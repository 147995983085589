import * as React from 'react';
import { ExclamationCircleOutlined, PlusCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Typography, Table, Space, Pagination, Input, Button, Form, Modal, Row, Col, InputNumber, Tooltip } from 'antd';
import { useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { apiInstance } from "../../services/Api/base";
import './Itineraries.css';

const { Title } = Typography;
const { confirm } = Modal;
const { Search } = Input;

function Itineraries() {

  const { t } = useTranslation();
  
  const onFormChange = (changedValues, allValues) => {
    setItinerary(allValues);
  };

  const columns = [
    {
      title: t("originLabel"),
      rowKey: 'id',
      width: '35%',
      children: [
        {
          title: t("clientLabel"),
          dataIndex: "from",
          key: "from",
        },
        {
          title: t("locationLabel"),
          dataIndex: "fromLocation",
          key: "fromLocation",
        },
      ],
    },
    {
      title: t("destinationLabel"),
      width: '35%',
      children: [
        {
          title: t("clientLabel"),
          dataIndex: "to",
          key: "to",
        },
        {
          title: t("locationLabel"),
          dataIndex: "toLocation",
          key: "toLocation",
        },
      ],
    },
    {
      children: [
        {
          title: t('distanceLabel'),
          dataIndex: 'distance',
          key: 'distance',
          render: (text, _) => (
            <>
              <Typography.Text>{text}</Typography.Text>
              <Typography.Text disabled> km</Typography.Text>
            </>
          )
        },    
      ],
    },
    {
      children: [
        {
          title: t('actionLabel'),
          key: 'action',
          width: 140,
          align: 'right',
          render: (_, record) => (
            <Space size="middle">
              <Tooltip placement="left" title={t("deleteActionLabel")}>
                <DeleteOutlined className='IconTable' onClick={() => onDelete(record.id, record.from, record.to)} />
              </Tooltip>
              <Tooltip placement="right" title={t("editActionLabel")}>
                <EditOutlined className='IconTable' onClick={() => onEdit(record)} />
              </Tooltip>
            </Space>
          )
        }
      ]
    },
  ]

  const {companyId} = useParams();
  const companyName = useStoreState((state) => state.companyModel.name);
  const emptyItinerary = { fromLocation: null, from: null, toLocation: null, to: null, distance: null, id: null };
  const [loadingItineraries, setLoadingItineraries] = React.useState(false);
  const [itineraries, setItineraries] = React.useState(null);
  const [pagination, setPagination] = React.useState(null);
  const [current, setCurrent] = React.useState(1);
  const [modal, setModal] = React.useState({open: false});
  const [itinerary, setItinerary] = React.useState(emptyItinerary);
  const [form] = Form.useForm();

  const [searchText, setSearchText] = React.useState('');

  const hideModal = (error) => {
    setItinerary(emptyItinerary);
    setModal({ open: false });
    form.setFieldsValue(emptyItinerary);
    if (error) {
      Modal.error({
        title: t("modalErrorTitle"),
        content: error.message,
      });
    }
  };

  const tripForm = (record) => {
    form.setFieldsValue(record);
    return (
      <Form
        form={form}
        name="trip"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={record}
        onValuesChange={onFormChange}
        autoComplete="off"
      >
        <Row justify="left">
          <Col sm={24}>
            <Title level={5}>{t("originLabel")}</Title>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item
                label={t('clientLabel')}
                name="from"
                rules={[{ required: true, message: t('originValidationError') }]}
            >
                <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
          <Form.Item
                label={t('locationLabel')}
                name="fromLocation"
                rules={[{ required: true, message: t('originValidationError') }]}
            >
                <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item
                label={t('distanceLabel')}
                name="distance"
                rules={[{ required: true, message: t('distanceValidationError') }]}
            >
                <InputNumber placeholder="100" min="1" max="5000" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="left">
          <Col sm={24}>
            <Title level={5}>{t("destinationLabel")}</Title>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item
                label={t('clientLabel')}
                name="to"
                rules={[{ required: true, message: t('destinationValidationError') }]}
            >
                <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item
                label={t('locationLabel')}
                name="toLocation"
                rules={[{ required: true, message: t('destinationValidationError') }]}
              >
                <Input />
              </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <label></label>
          </Col>
        </Row>
        <Form.Item
          name="id"
          hidden
        >
          <Input />
        </Form.Item>
      </Form>
    )
  };

  const onDelete = (inineraryId, from, to) => {
    confirm({
        title: `${t('deleteItineraryFrom')} ${from} ${t('to')} ${to}?`,
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          apiInstance.delete(`/itineraries/itineraries/${inineraryId}`)
            .then(() => {
              getItineraries(current)
            })
            .catch(error => {
              console.warn('error', error)
            })
        },
        onCancel: () => {
          setModal({open: false})
        }
    })
  };

  const onAdd = () => {
    setItinerary(emptyItinerary);
    setModal({
      open: true,
      title: <Title level={3}>{t("addItineraryLabel")}</Title>,
      content: tripForm(emptyItinerary)
    })
  };

  const onEdit = (record) => {
    setItinerary(record)
    setModal({
      open: true,
      title: <Title level={3}>{t("editItineraryLabel")}</Title>,
      content: tripForm(record)
    })
  };

  const handleSearch = (
    selectedKeys,
  ) => {
    typeof selectedKeys === 'object' ? selectedKeys = selectedKeys.target.value : selectedKeys = selectedKeys;
    if (selectedKeys.length < 3 && selectedKeys !== '') return;
    setItineraries(null);
    setCurrent(1);
    setSearchText(selectedKeys);
  };


  const getItineraries = async (current) => {
    setLoadingItineraries(true);
    apiInstance.get(`/itineraries/itineraries?companyId=${companyId}&page=${current - 1}${ searchText ? `&search=${searchText}` : ''}`)
      .then((response) => {
        setItineraries(response.data.content);
        setPagination(response.data.pagination);
        setLoadingItineraries(false);
      }
    );
  };

  const onChange = page => {
    setCurrent(page);
  };

  const createAddCall = (e)  => {
    return form.validateFields().then((_) => {
      return apiInstance({
        method: 'post',
        url: `/itineraries/itineraries`,
        data: {...itinerary, companyId: companyId},
      })
    })
  }

  const createEditCall = (e)  => {
    return form.validateFields().then((values) => {
      const {id: _, ...itineraryBody} = itinerary;
      return apiInstance({
        method: 'put',
        url: `/itineraries/itineraries/${itinerary.id}`,
        data: {...itineraryBody, companyId: companyId},
      })
    })
  }

  const submitItinerary = (e) => {
    e.currentTarget.setAttribute('disabled', '');
    let instance = itinerary.id ? createEditCall(e): createAddCall(e);
    instance.then(() => {
      getItineraries(current);
      form.resetFields();
      hideModal();
    })
    .catch(error => {
      console.warn('error', error);
    })
  };

  React.useEffect(() => {
    if(searchText && searchText.length > 2) {
      let timer = setTimeout(() => {
        getItineraries(current)
      }, 400);

      return () => clearTimeout(timer)
    } else {
      getItineraries(current)
    }
  }, [current, searchText]);

  React.useEffect(() => {
    document.title = `Itineraries${typeof companyName === 'string' ? ` — ${companyName}` : ''}`;
  }, [companyName]);

  return (
    <>
      <Row>
        <Col span={18}>
          <Title level={2} className="pageTitle">{t('itineraries')}</Title>
        </Col>
        <Col span={6} align="right"></Col>
      </Row>
      <Row justify="end">
        <Col sm={14}></Col>
        <Col sm={4}>
          <Search placeholder={t('itinerariesSearchPlaceholder')} onSearch={handleSearch} onChange={handleSearch} enterButton allowClear />
        </Col>
        <Col sm={2} align="right">
          <Button type="primary" size="medium" icon={<PlusCircleFilled />} onClick={() => {onAdd()}}>{t('itinerary')}</Button>
        </Col>

      </Row>
      <br />
      <Table loading={loadingItineraries} dataSource={itineraries} columns={columns} pagination={false} rowKey="id" />
      {itineraries && pagination.totalPages > 1 && <Pagination onChange={onChange} current={current} total={pagination.totalElements} showSizeChanger={false} />}
      {modal.open === true &&
      <Modal
        title={modal.title}
        open={modal.open}
        onOk={e => {
          submitItinerary(e)
        }}
        onCancel={() => {
          hideModal();
        }}
      >
        {modal.content}
      </Modal>
      }
    </>
  );
}

export default Itineraries;
