import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row, Table, Typography } from "antd";

const { Text } = Typography;

const inputColumns = (t) => {
  return (
    <Row>
      <Col span={6}>
        <Form.Item label={t("percentageLabel")} name="percentage">
          <InputNumber placeholder="100" min="1" max="100" readOnly/>
        </Form.Item>
      </Col>
    </Row>
  );
};

const extraColumns = (t) => {
  return [
    {
      title: t("numberOfDaysTitle"),
      dataIndex: "numberOfDays",
      key: "numberOfDays",
    },
    {
      title: t("p/e"),
      dataIndex: "trypLabel",
      key: "trypLabel",
    },
    {
      title: t("percentageTitle"),
      dataIndex: "percentage",
      key: "percentage",
      render: (text) => (
        <>
          <Typography.Text>{text}</Typography.Text>
          <Typography.Text disabled>%</Typography.Text>
        </>
      ),
    },
  ];
};

const extraSummary = (t, perDiem, onAdd, onChange, loadingIcon) => {
  return (
    <>
      <Table.Summary.Row className="subtotal">
        <Table.Summary.Cell colSpan={8} align="right">
          <Text strong>{t("valuePerDay")}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <InputNumber
            readOnly
            value={perDiem.valuePerDay}
            min="0"
            onChange={onChange}
            suffix={loadingIcon}
            addonAfter={
              <Text disabled className="smaller">
                {t("euroSymbol")}
              </Text>
            }
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={2} align="right">
          <Button
            type="primary"
            size="medium"
            icon={<PlusCircleFilled />}
            onClick={() => {
              onAdd();
            }}
          >
            {t("tripButton")}
          </Button>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};

const NationalPerDiem = {
  inputColumns,
  extraColumns,
  extraSummary,
};

export default NationalPerDiem;
