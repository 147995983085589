import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiInstance } from "../../services/Api/base";
import "./Calculator.css";
import {
  useParams,
} from "react-router-dom";
const { Title } = Typography;

const { Option } = Select;

const SalaryForm = () => {
  const [form] = Form.useForm();
  const [years, setYears] = useState(null);
  const [year, setYear] = useState(null);
  const [data, setData] = useState(null);
  const [extraIncome, setExtraIncome] = useState([]);
  const [extraCost, setExtraCost] = useState([]);
  const [includedExtraIncome, setIncludedExtraIncome] = useState([]);
  const [includedExtraCost, setIncludedExtraCost] = useState([]);
  const [disabledForm, setDisabledForm] = useState(false);
  const [simulationId, setSimulationId] = useState(null);
  const [simulationOutput, setSimulationOutput] = useState(null);
  const [salary, setSalary] = useState(0);
  const { locale } = useParams();

  const { t } = useTranslation();

  let Euro = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  let Percentage = new Intl.NumberFormat("de-DE", {
    style: "percent",
    maximumFractionDigits: 2, 
    minimumFractionDigits: 2
  });

  const incomeHandleAdd = (value) => {
    form.setFieldsValue({ "extra-income": null });
    let selectedIncome = extraIncome.find((entry) => {
      let exclusivityClause = entry.exclusiveWithSalaryEntryCodes;
      if (exclusivityClause.length > 0) {
        exclusivityClause.map((exclusion) => {
          let removeExclusive = extraIncome.find((entry) => {
            return entry.salaryEntryCode === exclusion;
          });
          if (removeExclusive) {
            removeExclusive.disabledByExclusivity = true;
          }
        });
      }
      return entry.salaryEntryCode === value;
    });
    selectedIncome.valueValue = selectedIncome.defaultValue;
    selectedIncome.monthValue = selectedIncome.defaultMonths;
    selectedIncome.disabledByExclusivity = true;
    setIncludedExtraIncome([...includedExtraIncome, selectedIncome]);
  };

  const costHandleAdd = (value) => {
    form.setFieldsValue({ "extra-cost": null });
    let selectedCost = extraCost.find((entry) => {
      return entry.costEntryCode === value;
    });
    selectedCost.disabledByExclusivity = true;
    setIncludedExtraCost([...includedExtraCost, selectedCost]);
  };

  const incomeHandleRemove = (value) => {
    let includedExtraIncomeTempArray = [...includedExtraIncome];
    let extraIncomeTempArray = [...extraIncome];
    includedExtraIncomeTempArray.find((entry) => {
      let exclusivityClause = entry.exclusiveWithSalaryEntryCodes;
      if (exclusivityClause.length > 0) {
        exclusivityClause.map((exclusion) => {
          extraIncomeTempArray.find((entry) => {
            if (entry.salaryEntryCode === exclusion) {
              entry.addByDefault = false;
              entry.disabledByExclusivity = false;
              return true;
            } else {
              return false;
            }
          });
        });
      }
      if (entry.salaryEntryCode === value) {
        entry.addByDefault = false;
        entry.disabledByExclusivity = false;
        return true;
      } else {
        return false;
      }
    });
    let removeIncludedExtraIncomeIndex = includedExtraIncomeTempArray.findIndex(
      (entry) => {
        return entry.salaryEntryCode === value;
      }
    );
    includedExtraIncomeTempArray.splice(removeIncludedExtraIncomeIndex, 1);
    setExtraIncome(extraIncomeTempArray);
    setIncludedExtraIncome(includedExtraIncomeTempArray);
  };

  const costHandleRemove = (value) => {
    let includedExtraCostTempArray = [...includedExtraCost];
    includedExtraCostTempArray.find((entry) => {
      if (entry.costEntryCode === value) {
        entry.addByDefault = false;
        entry.disabledByExclusivity = false;
        return true;
      } else {
        return false;
      }
    });
    let removeincludedExtraCostIndex = includedExtraCostTempArray.findIndex(
      (entry) => {
        return entry.costEntryCode === value;
      }
    );
    includedExtraCostTempArray.splice(removeincludedExtraCostIndex, 1);
    setIncludedExtraCost(includedExtraCostTempArray);
  };

  const getExtraIncomeItem = (incomeId) =>{
    return [...includedExtraIncome].find((entry) => {
      return entry.salaryEntryCode === incomeId;
    });
  }

  const getStatus = (value) => {
    const percentage = (value / salary) * 100;
    if (percentage > 25 && percentage < 35 ){
      return 'warning';
    } else if(percentage > 35) {
      return 'error'; 
    } else {
      return undefined;
    }
  }

  const updateStatus = (incomeId, value) => {
    if(['SE_EXTRA_KMS','SE_AC_NACIONAL'].indexOf(incomeId) > -1){    
      const item = getExtraIncomeItem(incomeId);
      item.status = getStatus(value);
    }
  }

  const onIncomeChange = (value, type, id) => {
    updateStatus(id, value);
    let tempArray = [...includedExtraIncome];
    const item = getExtraIncomeItem(id);
    if(item){
      item[type] = value;
      setIncludedExtraIncome(tempArray);  
    }
  };

  const onCostChange = (value, id) => {
    let tempArray = [...includedExtraCost];
    let item = includedExtraCost.find((entry) => {
      return entry.costEntryCode === id;
    });
    item.value = value;
    setIncludedExtraCost(tempArray);
  };

  const resetForm = () => {
    form.resetFields();
    setYear(null);
    setData(null);
    setSimulationId(null);
    setSimulationOutput(null);
    setSalary(0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onFinish = (values) => {
    let tempForm = {};
    tempForm.year = year;
    tempForm.householdDetails = {
      locationCode: values.locations,
      maritalStatusCode: values.status,
      descendants: values.dependants,
      handicap: {
        own: values.checkboxes?.includes("own") ? true : false,
        partner: values.checkboxes?.includes("partner") ? true : false,
        descendant: values.checkboxes?.includes("descendant") ? true : false,
      },
    };
    tempForm.salaryStandardEntries = {
      grossSalary: {
        value: values.grossSalary,
        months: values.paychecks || 14,
      },
    };
    tempForm.salaryExtraEntries = includedExtraIncome.map((entry) => {
      let newEntry = {
        salaryEntryCode: entry.salaryEntryCode,
        salaryEntryDetails: {
          value: entry.valueValue,
          months: entry.monthValue,
        },
      };
      return newEntry;
    });
    tempForm.initialCosts = includedExtraCost.map((entry) => {
      let newEntry = {
        costEntryCode: entry.costEntryCode,
        value: entry.value,
      };
      return newEntry;
    });
    setDisabledForm(true);
    apiInstance
      .post(`/hr-simulation/hr-simulation`, tempForm, {
        headers: {
          'Accept-Language': locale,
        }}).then((response) => {
        setSimulationId(response.data.simulationId);
        setSimulationOutput(response.data.simulationOutput);
        setDisabledForm(false);
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
        notification.success({
          message: "Success!",
          description: "We got results for you ;)",
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        setDisabledForm(false);
        let violationList = "";
        error.response.data.violations.map((violation) => {
          violationList += `${violation.field}: ${violation.message}\n`;
        });
        notification.error({
          message: "There seems to have been an error!",
          description: `
            Error: ${error.response.data.title}
            Status: ${error.response.data.status}
            Violations: ${violationList}
          `,
          placement: "bottomRight",
        });
      });
  };

  const getYears = async () => {
    apiInstance
      .get(`/hr-simulation/reference-data-years`)
      .then((response) => {
        setYears(response.data);
      })
      .catch((error) => {
        notification.error({
          message: "There seems to have been an error!",
          description: `Unfortunately we couldn't get the years available.`,
          placement: "bottomRight",
        });
      });
  };

  useEffect(() => {
    const getYearData = async () => {
      apiInstance
        .get(`/hr-simulation/reference-data/${year}`,{
          headers: {
            'Accept-Language': locale,
        }})
        .then((response) => {
          setData(response.data);
          setExtraIncome(response.data.salaryExtraEntries);
          setExtraCost(response.data.costEntries);
          setIncludedExtraIncome(
            response.data.salaryExtraEntries.filter((entry) => {
              entry.status = undefined;
              if (entry.addByDefault === true) {
                let exclusivityClause = entry.exclusiveWithSalaryEntryCodes;
                if (exclusivityClause.length > 0) {
                  exclusivityClause.map((exclusion) => {
                    let removeExclusive = response.data.salaryExtraEntries.find(
                      (entry) => {
                        return entry.salaryEntryCode === exclusion;
                      }
                    );
                    if (removeExclusive) {
                      removeExclusive.disabledByExclusivity = true;
                    }
                  });
                }
                entry.valueValue = entry.defaultValue;
                entry.monthValue = entry.defaultMonths;

                return true;
              } else {
                return false;
              }
            })
          );
          setIncludedExtraCost(
            response.data.costEntries.filter((entry) => {
              if (entry.addByDefault === true) {
                entry.value = entry.defaultValue;
                return true;
              } else {
                return false;
              }
            })
          );
        })
        .catch((error) => {
          notification.error({
            message: "There seems to have been an error!",
            description: `Unfortunately we couldn't get the data for the year ${year}. Try again, or select another year.`,
            placement: "bottomRight",
          });
        });
    };

    year && getYearData();
  }, [year]);

  useEffect(() => {
    document.title = t("salaryCalculator");
    getYears();
  }, []);

  const renderFormat = (itemType, itemValue) => {
    switch (itemType) {
      case 'PERCENTAGE':
        return Percentage.format(itemValue / 100);
      case 'CURRENCY':
        return Euro.format(itemValue);
      default:
        return itemValue;
    }
  };

  return (
    <>
      <Title level={2} className="pageTitle">
        {t("salaryCalculator")}
      </Title>
      <div className="formWrapper">
        {!years && !year && <LoadingOutlined className="loader" />}
        {years && !year && (
          <>
            <Row justify="center">
              <Col sm={24} md={21}>
                <Title level={3}>{t("selectAYear")}</Title>
                <Divider />
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={8} offset={8}>
                <Form
                  form={form}
                  layout="vertical"
                  name="salary-form"
                  onFinish={onFinish}
                  disabled={years === null}
                >
                  <Form.Item label={t("availableYears")} name="years">
                    {years &&
                      years.map((year, index) => {
                        return (
                          <Button
                            key={`year-${index}`}
                            onClick={() => setYear(year)}
                            block
                          >
                            {year}
                          </Button>
                        );
                      })}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </>
        )}
        {years && year && !data && <LoadingOutlined className="loader" />}
        {year && data && (
          <Form
            form={form}
            layout="vertical"
            name="salary-form"
            onFinish={onFinish}
            disabled={data === null || disabledForm === true}
          >
            <Row justify="center">
              <Col sm={24} md={21}>
                <Title level={3}>{t("inputs")}</Title>
                <Divider />
              </Col>
            </Row>
            <Row justify="center">
              <Col sm={24} md={10}>
                <Form.Item
                  label={t("location")}
                  name="locations"
                  rules={[
                    {
                      required: true,
                      message: t("pleaseTellUsWhereYouLive"),
                    },
                  ]}
                >
                  <Select placeholder={t("pleaseSelectALocation")}>
                    {data.locations &&
                      data.locations.map((location, index) => {
                        return (
                          <Option
                            key={`location-${index}`}
                            value={location.locationCode}
                          >
                            {location.locationDescription}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={{ span: 10, offset: 1 }}>
                <Form.Item
                  label={t("status")}
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: t("tellUsYourLegalStatus"),
                    },
                  ]}
                >
                  <Select placeholder={t("tellUsYourLegalStatus")}>
                    {data.maritalStatuses &&
                      data.maritalStatuses.map((maritalStatus, index) => {
                        return (
                          <Option
                            key={`maritalStatus-${index}`}
                            value={maritalStatus.maritalStatusCode}
                          >
                            {maritalStatus.maritalStatusDescription}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col sm={24} md={10}>
                <Row justify="space-between">
                  <Col xs={24} sm={24}>
                    <Form.Item
                      label={t("grossMonthlySalary")}
                      name="grossSalary"
                      rules={[
                        {
                          required: true,
                          message: t("whatIsTheGrossMonthlySalary"),
                        },
                      ]}
                    >
                      <InputNumber
                        addonAfter="€"
                        placeholder={t("grossMonthlySalary")}
                        min={1}
                        max={9999999}
                        onChange={(value) => setSalary(value) }
                        className="fullWidth"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col sm={24} md={{ span: 10, offset: 1 }}>
                <Form.Item
                  label={t("dependants")}
                  name="dependants"
                  rules={[
                    {
                      required: true,
                      message: t("howManyDependantsDoYouHave"),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder={t("numberOfDependants")}
                    min={0}
                    max={100}
                    className="fullWidth"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={24} sm={{ span: 21 }}>
                <Form.Item
                  label={t("disability")}
                  name="checkboxes"
                  valuePropName="checked"
                >
                  <Checkbox.Group>
                    <Checkbox value="own">{t("own")}</Checkbox>
                    <Checkbox value="partner">{t("partner")}</Checkbox>
                    <Checkbox value="descendant">{t("descendant")}</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col sm={24} md={21}>
                <Card
                  title={
                    <Row align="middle">
                      <Col xs={24} sm={12}>
                        <Title level={5} className="margin0">
                          {t("additionalIncome")}
                        </Title>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="extra-income"
                          rules={[
                            {
                              required: false,
                              message: t("additionalIncomeQuestion"),
                            },
                          ]}
                          className="marginBottom0"
                        >
                          <Select
                            placeholder={t("selectAdditionalIncomeToInclude")}
                            onChange={incomeHandleAdd}
                            allowClear
                          >
                            {extraIncome &&
                              extraIncome.map((income, index) => {
                                return (
                                  <Option
                                    key={`income-${index}`}
                                    value={income.salaryEntryCode}
                                    disabled={
                                      income.addByDefault ||
                                      income.disabledByExclusivity
                                    }
                                  >
                                    {income.salaryEntryDescription}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  }
                >
                  {includedExtraIncome &&
                    includedExtraIncome.map((income, index) => {
                      return (
                        <>
                          <Row
                            justify="space-between"
                            align="middle"
                            key={`includedExtraCost-${income.salaryEntryCode}`}
                          >
                            <Col xs={12}>{income.salaryEntryDescription}</Col>
                            <Col xs={5}>
                              <InputNumber
                                className="fullWidth"
                                status={income.status}
                                step={
                                  income.incrementStep
                                    ? income.incrementStep
                                    : 1
                                }
                                min={1}
                                max={income.maxValue ? income.maxValue : null}
                                placeholder={t("valueOfAdditionalIncome")}
                                addonAfter="€"
                                defaultValue={income.valueValue}
                                onChange={(value) => {
                                  onIncomeChange(
                                    value,
                                    "valueValue",
                                    income.salaryEntryCode
                                  );
                                }}
                              />
                            </Col>
                            <Col xs={5}>
                              <InputNumber
                                readOnly
                                className="fullWidth"
                                min={1}
                                max={14}
                                placeholder={t("lengthOfAdditionalIncome")}
                                addonAfter={t("months")}
                                defaultValue={income.monthValue}
                                onChange={(value) => {
                                  onIncomeChange(
                                    value,
                                    "monthValue",
                                    income.salaryEntryCode
                                  );
                                }}
                              />
                            </Col>
                            <Col xs={1} align="right">
                              <Button
                                type="text"
                                shape="circle"
                                size="small"
                                icon={<CloseCircleOutlined />}
                                onClick={() => {
                                  incomeHandleRemove(income.salaryEntryCode);
                                }}
                              ></Button>
                            </Col>
                          </Row>
                          <br />
                        </>
                      );
                    })}
                </Card>
                &nbsp;
                <Card
                  title={
                    <Row align="middle">
                      <Col xs={24} sm={12}>
                        <Title level={5} className="margin0">
                          {t("initialSetupExpenses")}
                        </Title>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="extra-cost"
                          rules={[
                            {
                              required: false,
                              message: t("extraCostsQuestion"),
                            },
                          ]}
                          className="marginBottom0"
                        >
                          <Select
                            placeholder={t(
                              "selectInitialSetupExpensesToInclude"
                            )}
                            onChange={costHandleAdd}
                            allowClear
                          >
                            {extraCost &&
                              extraCost.map((cost, index) => {
                                return (
                                  <Option
                                    key={`cost-${index}`}
                                    value={cost.costEntryCode}
                                    disabled={
                                      cost.addByDefault ||
                                      cost.disabledByExclusivity
                                    }
                                  >
                                    {cost.costEntryDescription}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  }
                >
                  {includedExtraCost &&
                    includedExtraCost.map((cost, index) => {
                      return (
                        <>
                          <Row
                            justify="space-between"
                            align="middle"
                            key={`includedExtraCost-${cost.costEntryCode}`}
                          >
                            <Col xs={17}>{cost.costEntryDescription}</Col>
                            <Col xs={5}>
                              <InputNumber
                                readOnly
                                className="fullWidth"
                                min={1}
                                placeholder={t("valueOfAdditionalCost")}
                                addonAfter="€"
                                defaultValue={cost.value}
                                onChange={(value) => {
                                  onCostChange(value, cost.costEntryCode);
                                }}
                              />
                            </Col>
                            <Col xs={1} align="right">
                              <Button
                                type="text"
                                shape="circle"
                                size="small"
                                icon={<CloseCircleOutlined />}
                                onClick={() => {
                                  costHandleRemove(cost.costEntryCode);
                                }}
                              ></Button>
                            </Col>
                          </Row>
                          <br />
                        </>
                      );
                    })}
                </Card>
              </Col>
            </Row>
            &nbsp;
            <Row justify="center">
              <Col sm={24} md={21}>
                <Form.Item
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button type="reset" htmlType="reset" onClick={resetForm}>
                    {t("resetForm")}
                  </Button>
                  &nbsp; &nbsp;
                  <Button type="primary" htmlType="submit">
                    {t("runSimulation")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {simulationOutput && (
          <>
            <Row justify="center">
              <Col sm={24} md={21}>
                <Title id="results" level={3}>
                  {t("results")}
                </Title>
                <Divider />
              </Col>
            </Row>
            <Row justify="center">
              <Col sm={24} md={10}>
                <Card title={t("monthlyRemuneration")}>
                  <Row justify="center" className="paddingBottom20">
                    <Col sm={24} md={24}>
                      <table className="fullWidth">
                        <tbody>
                          <tr>
                            <td width="50%">{t("grossSalary")}</td>
                            <td width="50%" align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.salarySimulation.monthly.grossSalary
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{t("otherEntries")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.salarySimulation.monthly.otherEntries
                                )
                              }
                            </td>
                          </tr>

                          {simulationOutput.salarySimulation.monthly.irsDetails.map(
                            ({ itemDescription, itemType, itemValue }, index) => {
                              return (
                                <tr key={`irs-details-${index}`}>
                                  <td>{itemDescription}</td>
                                  <td align="right">
                                    { renderFormat(itemType, itemValue) }
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          <tr>
                            <td>{t("irsTaxRate")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'PERCENTAGE', 
                                  simulationOutput.salarySimulation.monthly.irsTaxRate
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{t("irsTaxValue")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.salarySimulation.monthly.irsTaxValue
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{t("socialSecurityValue")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.salarySimulation.monthly.socialSecurityValue                                
                                )
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <strong style={{ display: "block", marginTop: "24px" }}>
                        {t("netSalaryPossibilities")}
                      </strong>
                      <table className="fullWidth">
                        <tbody>
                          {simulationOutput.salarySimulation.monthly.netSalaryPossibilities.map(
                            (possibility, index) => {
                              return (
                                <tr key={`netSalaryPossibilities-${index}`}>
                                  <td width="50%">{possibility.description}</td>
                                  <td width="50%" align="right">
                                    {
                                      renderFormat(
                                        'CURRENCY', 
                                        possibility.value
                                      )
                                    }
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={24} md={{ span: 10, offset: 1 }}>
                <Card title={t('yearlyRemuneration')}>
                  <Row justify="left" className="paddingBottom20">
                    <Col sm={24} md={24}>
                      <table className="fullWidth">
                        <tbody>
                          <tr>
                            <td width="50%">{t("grossSalary")}</td>
                            <td width="50%" align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.salarySimulation.yearly.grossSalary
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{t("netSalary")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.salarySimulation.yearly.netSalary
                                )
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </Card>
                <br />
                <br />
                <Card title={t('companyCost')}>
                  <Row justify="left" className="paddingBottom20">
                    <Col sm={24} md={24}>
                      <table className="fullWidth">
                        <tbody>
                          <tr>
                            <td width="50%">{t("monthly")}</td>
                            <td width="50%" align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.companyCostsSimulation.monthly.totalCost
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{t("yearly")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.companyCostsSimulation.yearly.totalCost
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{t("initialCosts")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.companyCostsSimulation.initialCosts.totalCost
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{t("firstMonthCosts")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.companyCostsSimulation
                                  .firstMonth.totalCost
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{t("firstYearCosts")}</td>
                            <td align="right">
                              {
                                renderFormat(
                                  'CURRENCY', 
                                  simulationOutput.companyCostsSimulation.firstYear.totalCost
                                )
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default SalaryForm;
