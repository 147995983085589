import useHandleApiData from "../../../global/hooks/useHandleApiData";
import { Employees } from "../../../services/Api/Employees";

export const useEmployeeContractKMs = (employeeId: string) => {
  return useHandleApiData(
    employeeId,
    Employees.contractKMs,
    Employees.createContractKMs,
    Employees.deleteContractKMs
  );
};
