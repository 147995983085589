import { useEffect} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { Typography, Card, Select, Row, Col } from 'antd';
import { TeamOutlined, CarOutlined, CalculatorOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

import './Admins.css';

const { Title } = Typography;
const { Option } = Select;

function Admins() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale, companyId } = useParams();

  const companyName = useStoreState((state) => state.companyModel.name);
  const companiesCollection = useStoreState((state) => state.companiesCollection.companiesAccess);
  const setCompany = useStoreActions((state) => state.companyModel.setCompany);
  const rbacObj = useStoreState((state) => state.rbacModel?.rbac);
  const setPermissions = useStoreActions((state) => state.rbacModel.setPermissions);
  const permissions = useStoreState((state) => state.rbacModel.permissions)
  
  useEffect(() => {
    let company = rbacObj?.access?.companiesAccess?.find(company => company.id === companyId);
    if(company) {
      let name = company.name;
      setPermissions(company.permissions);
    }
  }, [rbacObj, permissions]);

  useEffect(() => {
    document.title = typeof companyName === 'string' ? `${t('administrators')} — ${companyName}` : t('administrators');
  }, [companyName]);

  useEffect(() => {
    if (locale !== 'en' && locale !== 'pt') {
      console.log('Locale not recognized. Redirecting to 404.', locale, window.location.href, hash,locale !== 'en', locale !== 'pt', !hash.includes('code'));
      navigate(`/pt/404/`);
    }
  }, [locale]);

  const onChange = (value) => {
    navigate(`/${locale}/company/${value}/`);
    let company = companiesCollection?.find(company => company.id === value);
    setCompany({name: company.name, id: company.id});
    setPermissions(company.permissions);
  };

  return (
    <>
      {!(rbacObj?.access?.globalAccess?.permissions?.length > 0 || permissions?.length > 0) && 
      <div className='loadingWrapper'>
        <LoadingOutlined className='loader'/>
      </div>}
      {(rbacObj?.access?.globalAccess?.permissions?.length > 0 || permissions?.length > 0) && 
      <>
        <Title level={2} className="pageTitle">{t('administrators')}</Title>
        <div className='contentWrapper'>      
          <div className="cardsWrapper">
            <h3>{t('globalAccess')}</h3>
            {rbacObj?.access?.globalAccess?.permissions?.length > 0 && rbacObj?.access?.globalAccess?.permissions?.find(obj => obj === "simulator") &&
              <Link key="2" to={`/${locale}/calculator`} >
                <Card hoverable={true} >
                  <Row>
                    <Col xs={8} sm={4} className='cardIcon'><CalculatorOutlined className="icon" /></Col>
                    <Col xs={16} sm={20} className='cardDescription'>
                      <h3>{t('calculator')}</h3>
                      <p>{t('salarySimulator')}</p>
                    </Col>
                  </Row>
                </Card>
              </Link>
            }
            {
              
            }
            <hr/>
          </div>
          <div className='selectWrapper'>
            <h3>{t('pleaseSelectACompany')}</h3>
            <Select
              value={companyId}
              placeholder={t('selectACompany')}
              optionFilterProp="children"
              onChange={onChange}
              size="large"
              key="key"
            >
            {companiesCollection?.length > 0 && companiesCollection?.map((company, index) => 
              <Option value={company.id} key={index}>{company.name}</Option>
            )}
            </Select>
          </div>
          <div className="cardsWrapper">
            {companyId && permissions?.length > 0 && permissions?.find(obj => obj === "employees") &&
            <Link key="0" to={companyId ? `/${locale}/company/${companyId}/employees` : false}>
              <Card hoverable={companyId ? true : false} >
                <Row>
                  <Col xs={8} sm={4} className='cardIcon'><TeamOutlined className="icon" /></Col>
                  <Col xs={16} sm={20} className='cardDescription'>
                    <h3>{t('manageEmployees')}</h3>
                    <p>{t('travelSheets')}</p>
                  </Col>
                </Row>
              </Card>
            </Link>
            }
            {companyId && permissions?.length > 0 && permissions?.find(obj => obj === "travel-reports") &&
            <Link key="1" to={companyId ? `/${locale}/company/${companyId}/itineraries` : false} >
              <Card hoverable={companyId ? true : false} >
                <Row>
                  <Col xs={8} sm={4} className='cardIcon'><CarOutlined className="icon" /></Col>
                  <Col xs={16} sm={20} className='cardDescription'>
                    <h3>{t('manageItineraries')}</h3>
                    <p>{t('tripTemplates')}</p>
                  </Col>
                </Row>
              </Card>
            </Link>
            }
          </div>
        </div>
      </>}
    </>
  );
}

export default Admins;
