import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import error from './error.svg';
import './NotFound.css';

const { Title } = Typography;

function NotFound() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `404 Error — ${t('notFound')}`;
    }, []);

    return (
        <>
            <div className="site-layout-background" id="content">
                <Title level={1} className="notFoundPageTitle">{t('notFound')}</Title>
                <img src={error} alt="404 error" className='image' />
            </div>
        </>
    )
}

export default NotFound;