import { useStoreState } from "easy-peasy";
import i18next from "i18next";
import React, { useEffect } from "react";
import { initReactI18next } from "react-i18next";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import Admins from "./views/admins";
import App from "./views/app";
import Calculator from "./views/calculator";
import Employees from "./views/employees";
import Itineraries from "./views/itineraries";
import Login from "./views/login";
import Logout from "./views/logout";
import NotFound from "./views/notFound";
import PerDiem from "./views/perDiem";
import PerDiems from "./views/perDiems";
import TravelReport from "./views/travelReport";
import TravelReports from "./views/travelReports";

import enUS from "./translations/en-US.json";
import ptPT from "./translations/pt-PT.json";

import "./index.css";
import Employee from "./views/employee";

import dayjs from "dayjs";
import "dayjs/locale/pt";

const Router = () => {
  const { companyId } = useParams();
  const [_, setCompany] = React.useState();
  const localeState = useStoreState((state) => state.userModel.locale);
  const rbacObj = useStoreState((state) => state.rbacModel.rbac);
  const permissions = useStoreState((state) => state.rbacModel.permissions);

  const clearAccess = (access) => {
    let location = window.location.pathname;
    let path = location.split("/");
    let index = path.findIndex((obj) => obj === "company");
    let selectedCompany = rbacObj?.access?.companiesAccess?.find(
      (company) => company.id === path[index + 1]
    );
    if (selectedCompany?.permissions?.find((perm) => perm === access)) {
      return true;
    }
  };

  useEffect(() => {
    if (companyId) {
      setCompany(companyId);
    }
  }, [companyId, rbacObj, permissions]);

  dayjs.locale(localeState);

  if (!i18next.isInitialized) {
    i18next.use(initReactI18next).init({
      lng: localeState,
      debug: true,
      resources: {
        en: {
          translation: enUS,
        },
        pt: {
          translation: ptPT,
        },
      },
      fallbackLng: "pt",
    });
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={`/${localeState}`} />} />

        <Route path="/:locale/" element={<App />}>
          <Route path="/:locale/" element={<Admins />} />
          <Route path="/:locale/404/" element={<NotFound />} />

          {rbacObj?.access?.globalAccess.permissions.find(
            (obj) => obj === "simulator"
          ) && <Route path="/:locale/calculator/" element={<Calculator />} />}

          {rbacObj && clearAccess("employees") && (
            <>
              <Route
                path="/:locale/company/:companyId/employees/"
                element={<Employees />}
              />
              <Route
                path="/:locale/company/:companyId/itineraries/"
                element={<Itineraries />}
              />
            </>
          )}

          <Route path="/:locale/company/:companyId/" element={<Admins />} />

          {rbacObj && clearAccess("travel-reports") && (
            <>
              <Route
                path="/:locale/company/:companyId/employee/:employeeId/travel-report/:travelReportId/"
                element={<TravelReport />}
              />
              <Route
                path="/:locale/company/:companyId/employee/:employeeId/travel-reports/"
                element={<TravelReports />}
              />
              <Route
                path="/:locale/company/:companyId/employee/:employeeId/per-diem/:reportType/:perDiemId/"
                element={<PerDiem />}
              />
              <Route
                path="/:locale/company/:companyId/employee/:employeeId/per-diems/:reportType/"
                element={<PerDiems />}
              />
            </>
          )}

          <Route
            path="/:locale/company/:companyId/employee/:employeeId/"
            element={<Employee />}
          />
        </Route>

        <Route path="/:locale/login/" element={<Login />} />
        <Route path="/:locale/logout/" element={<Logout />} />

        <Route path="*" element={<Navigate to={`/${localeState}/404/`} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
