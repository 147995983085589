import { ConfigProvider, Layout } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { Outlet } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "./../../components/breadcrumbs";
import Footer from "./../../components/footer";
import Header from "./../../components/header";

import "./App.css";

import { useMsal } from "@azure/msal-react";
import { apiInstance, msalInstance } from "../../services/Api/base";
import enUS from "./../../translations/en-US";
import ptPT from "./../../translations/pt-PT";

const { Content } = Layout;

const App = () => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const localeState = useStoreState((state) => state.userModel.locale);
  const token = useStoreState((state) => state.userModel.token);
  const setToken = useStoreActions((state) => state.userModel.setToken);
  const setRBAC = useStoreActions((state) => state.rbacModel.setRBAC);
  
  const getMe = () => {
    apiInstance.get("/users/me").then((response) => {
      setRBAC(response.data);
    });
  };

  const cacheToken = (token) => {
    setToken(newToken);
    apiInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  }

  const getMeWithToken = async () => {
    if (token && typeof token !== "function" && token.length > 1) {
      getMe();
    } else {
      console.log("No token found");
      if (accounts[0]) {
        console.log("Trying to acquire token");
        await msalInstance
          .acquireTokenSilent({
            scopes: ["a6c49e8c-e5ef-44e3-a9b3-f9e9a70a9146/.default"],
            account: accounts[0],
          })
          .then((response) => {
            cacheToken(response.accessToken);
            getMe();
          })
          .catch((error) => {
            console.log("Error acquiring token", error);
            navigate(`/${localeState}/login/`, { replace: true });
          });
      } else {
        console.log("No accounts found");
        await msalInstance
          .loginPopup({
            scopes: ["a6c49e8c-e5ef-44e3-a9b3-f9e9a70a9146/.default"],
          })
          .then((response) => {
            cacheToken(response.accessToken);
            getMe();
          })
          .catch((error) => {
            console.log("Error logging you in to fetch token", [error]);
            navigate(`/${localeState}/login/`, { replace: true });
          });
      }
    }
  };

  React.useEffect(() => {
    getMeWithToken();
  }, [token]);

  return (
    <ConfigProvider locale={localeState === 'en' ? enUS : ptPT}>
      <Layout id="layout">
        <Header />
        <Content className="site-layout">
          <Breadcrumbs />
          <div className="site-layout-background" id="content">
            <Outlet />
          </div>
        </Content>
        <Footer />
      </Layout>
    </ConfigProvider>
  );
};

export default App;
