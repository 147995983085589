import { Col, Divider, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ICompany } from "./Company.interface";

const Company = ({ company }: { company: ICompany }) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col span={24}>
          <Divider orientation="left" orientationMargin="0">
            {t("company")}
          </Divider>
        </Col>
        <Col span={6}>
          <Text strong>{t("name")}</Text>
        </Col>
        <Col span={18}>
          <Text>{company.name}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Text strong>{t("address")}</Text>
        </Col>
        <Col span={18}>
          <Text>{company.address}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Text strong>{t("taxId")}</Text>
        </Col>
        <Col span={18}>
          <Text>{company.vatNumber}</Text>
        </Col>
      </Row>
    </>
  );
};

export default Company;
