import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { licensePlateRegEx } from '../../shared/licensePlate';
import dayjs from "dayjs";
import { useStoreState } from "easy-peasy";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { apiInstance } from "../../services/Api/base";
import { useEmployeeDetails } from "../employee/hooks/useEmployeeDetails";
import "./TravelReport.css";

const { Title, Text } = Typography;
const { confirm } = Modal;

function TravelReport() {
  const emptyRecord = {
    day: null,
    from: null,
    to: null,
    distance: null,
    reason: null,
    id: null,
    order: null,
  };
  const { travelReportId, employeeId } = useParams();
  const [travelReport, setTravelReport] = React.useState(null);
  const [modal, setModal] = React.useState({ open: false });
  const [saveStatus, setSaveStatus] = React.useState(null);
  const [trip, setTrip] = React.useState(emptyRecord);
  const [form] = Form.useForm();
  const [formLicensePlate] = Form.useForm();
  const [timer, setTimer] = React.useState(null);
  const companyName = useStoreState((state) => state.companyModel.name);
  const employee = useEmployeeDetails(employeeId);
  
  const { t } = useTranslation();

  let loadingIcon = null;
  switch (saveStatus) {
    case "loading":
      loadingIcon = <LoadingOutlined />;
      break;
    case "success":
      loadingIcon = <CheckCircleOutlined />;
      break;
    case "failed":
      loadingIcon = <CloseCircleOutlined />;
      break;
    default:
      break;
  }

  const onFormChange = (changedValues, allValues) => {
    setTrip(allValues);
  };

  const hideModal = (error) => {
    setTrip(emptyRecord);
    setModal({ open: false });
    form.setFieldsValue(emptyRecord);
    if (error) {
      Modal.error({
        title: t("modalErrorTitle"),
        content: error.message,
      });
    }
  };

  const tripForm = (record) => {
    form.setFieldsValue(record);
    return (
      <Form
        form={form}
        name="trip"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={record}
        onValuesChange={onFormChange}
        autoComplete="off"
      >
        <Row>
          <Col span={6}>
            <Form.Item
              label={t("dayLabel")}
              name="day"
              rules={[{ required: true, message: t("dayRequiredMessage") }]}
            >
              <InputNumber placeholder="1" min="1" max="31" />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              label={t("reasonLabel")}
              name="reason"
              rules={[{ required: true, message: t("reasonRequiredMessage") }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("originLabel")}
              name="from"
              rules={[{ required: true, message: t("originRequiredMessage") }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item
              label={t("destinationLabel")}
              name="to"
              rules={[
                { required: true, message: t("destinationRequiredMessage") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item
              label={t("distanceLabel")}
              name="distance"
              rules={[
                { required: true, message: t("distanceRequiredMessage") },
              ]}
            >
              <InputNumber placeholder="100" min="1" max="5000" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>
    );
  };

  const onDelete = (tripId, tripDay, month) => {
    confirm({
      title: t("deleteTripConfirmation", { day: tripDay.day, month: month}),
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        apiInstance
          .delete(
            `/travel-reports/travel-reports/kms/${travelReportId}/trips/${tripId}`
          )
          .then(() => {
            getTravelReport(travelReportId);
          })
          .catch((error) => {
            console.warn("error", error);
          });
      },
      onCancel: () => {
        setModal({ open: false });
      },
    });
  };

  const onAdd = () => {
    setTrip(emptyRecord);
    setModal({
      open: true,
      title: t("addTripTitle"),
      content: (
        <Space direction="vertical">
          {t("addTripContent")}
          {tripForm(emptyRecord)}
        </Space>
      ),
    });
  };

  const onEdit = (record) => {
    setTrip(record);
    setModal({
      open: true,
      title: t("editTripTitle"),
      content: tripForm(record),
    });
  };

  const submitTrip = (e) => {
    e.currentTarget.setAttribute("disabled", "");
    const isUpdate = trip.id !== null;
    apiInstance({
      method: isUpdate ? "put" : "post",
      url: `/travel-reports/travel-reports/kms/${travelReportId}/trips/${
        isUpdate ? trip.id : ""
      }`,
      data: { ...trip, id: isUpdate ? trip.id : null } ,
    })
      .then(() => {
        getTravelReport(travelReportId);
        form.resetFields();
        hideModal();
      })
      .catch((error) => {
        console.warn("error", error);
        form.resetFields();
        hideModal(error);
      });
  };

  const columns = [
    {
      title: t("dayTitle"),
      dataIndex: "day",
      key: "day",
      width: 60,
      align: "center",
    },
    {
      title: t("originTitle"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("destinationTitle"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("justificationTitle"),
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: t("kmsTitle"),
      dataIndex: "distance",
      key: "distance",
      render: (text, record) => (
        <>
          <Typography.Text>{text}</Typography.Text>
          <Typography.Text disabled> {t("km")}</Typography.Text>
        </>
      ),
    },
    {
      title: t("actionLabel"),
      key: "action",
      width: 160,
      align: "right",
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() =>
              onDelete(
                record.id,
                record,
                dayjs().month(travelReport.month - 1).format("MMMM")
              )
            }
          >
            {t("deleteActionLabel")}
          </a>
          <a onClick={() => onEdit(record)}>{t("editActionLabel")}</a>
        </Space>
      ),
    },
  ];

  const getTravelReport = async (current) => {
    apiInstance
      .get(`/travel-reports/travel-reports/kms/${current}`)
      .then((response) => {
        setTravelReport(response.data);
      })
      .catch((error) => {
        console.warn("error", error);
      });
  };

  const onChange = (e) => {
    if(licensePlateRegEx.test(e.target.value)){
      formLicensePlate.submit();
    }
  }

  const onFinish = (values) => {
    formLicensePlate.validateFields().then(() => { 
      setSaveStatus("loading");      
      const {amount: _, KMs: __, trips: ___, ... filteredTravel} = travelReport;
      apiInstance
        .put(`/travel-reports/travel-reports/kms/${travelReportId}`, {
          ... filteredTravel,
          employee: {
            ... filteredTravel.employee,
            carRegistration: values.carRegistration
          }
        })
        .then(() => {
          getTravelReport(travelReportId);
          setSaveStatus("success");
        })
        .catch((error) => {
          console.warn("error", error);
          setSaveStatus("failed");
        });
    })
  };

  const onDownload = () => {
    apiInstance
      .post(
        `travel-reports/travel-reports/export`,
        {
          reportIds: [travelReportId],
        },
        {
          headers: {
            "content-type": "application/json",
            accept: "application/pdf",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const fileName = `Kms - ${travelReport.employee.name} — ${travelReport.year}-${travelReport.month}.pdf`;
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  React.useEffect(() => {
    if(travelReportId && saveStatus === null && travelReport === null){
      getTravelReport(travelReportId);
    }
    if(travelReport) {
      document.title = `Travel Report — ${travelReport.year}/${
        travelReport.month
      }${typeof companyName === "string" ? " — " + companyName : ""}${
        typeof employee?.name === "string" ? " — " + employee.name : ""
      }`
    }
  }, [travelReportId, travelReport, saveStatus]);

  return (
    travelReport && (
      <>
        <Title level={2} className="pageTitle">
          {t("travelReportTitle")}
        </Title>
        <Row>
          <Col span={12}>
            <Title level={4}>{t("compensationForUseOfVehicle")}</Title>
          </Col>
          <Col span={12} align="right">
            <Button
              type="primary"
              size="small"
              icon={<DownloadOutlined />}
              onClick={onDownload}
            >
              {t("exportButton")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Divider orientation="left" orientationMargin="0">
                  {t("company")}
                </Divider>
              </Col>
              <Col span={6}>
                <Text strong>{t("name")}</Text>
              </Col>
              <Col span={18}>
                <Text>{travelReport.company.name}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Text strong>{t("address")}</Text>
              </Col>
              <Col span={18}>
                <Text>{travelReport.company.address}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Text strong>{t("taxId")}</Text>
              </Col>
              <Col span={18}>
                <Text>{travelReport.company.vatNumber}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={4} offset={8}>
            <Row>
              <Col span={24} align="right">
                <Title strong>{t("receipt")}</Title>
              </Col>
            </Row>
            <Row>
              <Col span={14} align="right">
                <Text type="secondary">{t("month")}</Text>
              </Col>
              <Col span={10} align="right">
                <Text type="secondary">{t("year")}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={14} align="right">
                <Text className="date">
                  {dayjs().month(travelReport.month - 1).format("MMMM")}
                </Text>
              </Col>
              <Col span={10} align="right">
                <Text className="date">{travelReport.year}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <br />
        <Table
          dataSource={travelReport.trips}
          columns={columns}
          pagination={false}
          size="middle"
          rowKey="id"
          summary={() => {
            return (
              <>
                <Table.Summary.Row className="subtotal">
                  <Table.Summary.Cell colSpan={4} align="right">
                    <Text strong>{t("totalDistance")}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text>
                      {travelReport.KMs}{" "}
                      <Text type="secondary" className="smaller">
                        {t("km")}
                      </Text>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Button
                      type="primary"
                      size="small"
                      icon={<PlusCircleFilled />}
                      onClick={() => {
                        onAdd(travelReportId);
                      }}
                    >
                      {t("tripButton")}
                    </Button>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row className="total">
                  <Table.Summary.Cell colSpan={4} align="right">
                    <Title level={4}>{t("totalAmount")}</Title>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="secondary">{t("multiplierSymbol")}</Text>{" "}
                    <Text type="secondary" className="euro">
                      {t("euroSymbol")}
                    </Text>{" "}
                    <Text strong>{travelReport.valuePerKM.toFixed(2)}</Text>{" "}
                    <Text disabled className="smaller">
                      {t("perKm")}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Title level={3}>
                      <Text type="secondary" className="euro">
                        {t("euroSymbol")}
                      </Text>{" "}
                      <Text strong>{travelReport.amount?.toFixed(2)}</Text>
                    </Title>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
        <br />
        <br />
        <Row>
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0">
              {t("payee")}
            </Divider>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={6}>
                <Text strong>{t("name")}</Text>
              </Col>
              <Col span={18}>
                <Text>{travelReport.employee.name}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Text strong>{t("address")}</Text>
              </Col>
              <Col span={18}>
                <Text>{travelReport.employee.address}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={6}>
                <Text strong>{t("taxId")}</Text>
              </Col>
              <Col span={18}>
                <Text>{travelReport.employee.vatNumber}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Text strong>{t("vehicle")}</Text>
              </Col>
              <Col span={9}>
                <Form
                  onFinish={onFinish}
                  form={formLicensePlate}
                  initialValues={ {carRegistration: travelReport.employee?.carRegistration}}
                >
                  <Form.Item
                    name="carRegistration"
                    rules={[
                      {
                        pattern: licensePlateRegEx,
                        message: t("enterValidLicensePlate"),
                      },
                    ]}
                    >
                    <Input 
                      placeholder={t("travelReportLicensePlate")} 
                      suffix={loadingIcon} 
                      onChange={onChange}
                      />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        {modal.open === true && (
          <Modal
            title={modal.title}
            open={modal.open}
            onOk={(e) => {
              submitTrip(e);
            }}
            onCancel={() => {
              hideModal();
            }}
          >
            {modal.content}
          </Modal>
        )}
      </>
    )
  );
}

export default TravelReport;
