import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const AntdFooter = Layout.Footer;

function Footer() {
    const { t } = useTranslation();
    return (
        <AntdFooter className='footer'>Nexus Group ©2023&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;{t('createdWith')}&nbsp;&nbsp;♥</AntdFooter>
    );
  }
  
  export default Footer;
  
