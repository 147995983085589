import { LoginOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { Button, Col, Layout, Menu, Row } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import i18next from "i18next";

import "./Header.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const AntdHeader = Layout.Header;

function Header() {
  let location = useLocation();
  const { t } = useTranslation();

  const { locale, companyId } = useParams();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const setToken = useStoreActions((state) => state.userModel.setToken);
  const companyName = useStoreState((state) => state.companyModel.name);
  const companiesCollection = useStoreState(
    (state) => state.companiesCollection.companiesAccess
  );
  const setCompanies = useStoreActions(
    (state) => state.companiesCollection.setCompanies
  );
  const setCompany = useStoreActions(
    (state) => state.companyModel.setCompany
  );
  const rbacObj = useStoreState((state) => state.rbacModel.rbac);
  const permissions = useStoreState((state) => state.rbacModel.permissions);
  const setPermissions = useStoreActions(
    (state) => state.rbacModel.setPermissions
  );

  const setLocale = useStoreActions((state) => state.userModel.setLocale);
  const [selectedKey, setSelectedKey] = React.useState(0);
  const [menubarJson, setMenubarJson] = React.useState();

  const setMenuItems = () => {
    let array = [];
    array.push({
      key: 0,
      path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
      label: t("home"),
    });
    if (permissions && permissions.length > 0) {
      permissions.map((perm) => {
        if (perm === "employees") {
          array.push({
            key: 1,
            path: `/${locale}/company/${companyId}/employees`,
            label: t("employees"),
          });
        }
        if (perm === "travel-reports") {
          array.push({
            key: 2,
            path: `/${locale}/company/${companyId}/itineraries`,
            label: t("itineraries"),
          });
        }
        return array;
      });
    }
    setMenubarJson(array);
    if (location.pathname.includes("itinerar")) {
      setSelectedKey("2");
    } else if (location.pathname.includes("employee")) {
      setSelectedKey("1");
    } else {
      setSelectedKey("0");
    }
  };

  const setCompaniesAsync = async (company) => {
    await setCompanies(rbacObj?.access?.companiesAccess);
    await setCompany({id: company.id, name: company.name});
    await setPermissions(company.permissions);
    await setMenuItems();
  };

  const updateHeader = () => {
    setCompanies(rbacObj?.access?.companiesAccess);
    companiesCollection?.find((company) => {
      if (company.id === companyId) {
        setCompaniesAsync(company);
        return true;
      }
    });
  };

  const changeLocaleFn = (url, newLocale) => {
    const localePattern = /\/([a-z]{2})\//;
    const match = url.match(localePattern);

    if (match) {
      return url.replace(localePattern, `/${newLocale}/`);
    } else {
      const domainPattern = /^(https?:\/\/[^\/]+)\//;
      const domainMatch = url.match(domainPattern);
      if (domainMatch) {
        return url.replace(domainPattern, `$1/${newLocale}/`);
      }
    }
    return url;
  };

  const changeLocale = () => {

    const newUrl = locale === "pt"? 
    changeLocaleFn(window.location.pathname, "en") :
    changeLocaleFn(window.location.pathname, "pt");

    const newLocale = locale === "pt"? "en" : "pt";
    setLocale(newLocale);
    navigate(newUrl, { replace: true });
    i18next.changeLanguage(newLocale);
  };

  const logout = () => {
    instance.logout().then((response) => {
      setToken(null);
      console.log("Logged out successfully");

      navigate(`/${locale}/login/`, { replace: true });
    });
  };

  React.useEffect(() => {
    updateHeader();
  }, [companyId, permissions, rbacObj, location]);

 const items = companyId ? 
 (
    menubarJson?.map((item) => (
            {
              label: <Link to={item.path}>{item.label}</Link>, 
              key: item.key   
            }
          )
    )
  )
 : [
  { 
    label: 
      <Link to={`/${locale}/${companyId ? `company/${companyId}/` : ""}`} >
    { t("home") }
    </Link>, 
    key: '0' 
  }
];

  return (
    <AntdHeader id="header">
      <Row justify="space-between">
        <Col span={12}>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["0"]}
            selectedKeys={companyId ? [selectedKey] : "0"}
            items={items}
          />
        </Col>
        <Col span={12} align="right">
          {companyId && (
            <span className="companyName">
              {companyName ? companyName : ""}
            </span>
          )}
          <Button type="link" onClick={changeLocale}>
            {locale === "pt" ? (
              <span className="fi fi-gb"></span>
            ) : (
              <span className="fi fi-pt"></span>
            )}
          </Button>
          <Button type="link" onClick={logout}>
            <LoginOutlined />
          </Button>
        </Col>
      </Row>
    </AntdHeader>
  );
}

export default Header;
