import { createStore, action } from "easy-peasy";

const userModel = {
    token: String,
    setToken: action((state, payload) => {
        state.token = payload;
    }),
    locale: 'pt',
    setLocale: action((state, payload) => {
        state.locale = payload;
    })
}

const rbacModel = {
    rbac: {},
    permissions: [],
    setRBAC: action((state, payload) => {
        state.rbac = payload;
    }),
    setPermissions: action((state, payload) => {
        state.permissions = payload;
    })
}

const employeeModel = {
    employee: {},
    setEmployee: action((state, payload) => {
        state.employee = payload;
    })
};

const companyModel = {
    id: String,
    name: String,
    setCompany: action((state, { name, id}) => {
        state.name = name;
        state.id = id;
    })
};

const companiesCollection = {
    companiesAccess: [],
    setCompanies: action((state, payload) => {
        state.companiesAccess = payload;
    })
};

const storeModel = {
    userModel,
    rbacModel,
    employeeModel,
    companyModel,
    companiesCollection
};

export default createStore(storeModel);
