import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import store from "../../store";

const API_BASE_URL = `https://${process.env.REACT_APP_API_URL}`;
const tenantId = "ff74da86-d52d-4816-95da-e9baccf4bfa3";
const clientId = "a6c49e8c-e5ef-44e3-a9b3-f9e9a70a9146";

const setToken = store.getActions().userModel.setToken;

const apiInstance = axios.create({
  baseURL: API_BASE_URL,
});

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: "/",
  },
});

apiInstance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    console.log("API interceptor error", error, error?.response);
    if (error?.response?.status === 401) {
      //TODO: Handle Unauthorized
      return await msalInstance
        .loginPopup({
          scopes: ["a6c49e8c-e5ef-44e3-a9b3-f9e9a70a9146/.default"],
        })
        .then((response) => {
          localStorage.setItem("token", response.accessToken);
          apiInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.accessToken}`;
          setToken(response.accessToken);
          console.log("API interceptor error. Logged back in.", error);
        })
        .catch(async (err) => {
          await msalInstance.logoutRedirect();
          console.log("API interceptor error. Login failed.", error, err);
        });
    } else {
      return error;
    }
  }
);

export { apiInstance, msalInstance };
