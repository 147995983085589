import { apiInstance } from "./base";

const { get, post, delete: del } = apiInstance;

const BASE_URL = "/employees/employees";

export interface LicensePlateParams {
  plateId: string;
  validSince: string;
}
export interface ContractKMsParams {
  value: number;
  validSince: string;
}

export const Employees = {
  index: () => get(BASE_URL),
  single: (id: string) => get(`${BASE_URL}/${id}`),
  licensePlates: (id: string) => get(`${BASE_URL}/${id}/license-plates`),
  createLicensePlate: (id: string, params: LicensePlateParams) =>
    post(`${BASE_URL}/${id}/license-plates`, params),
  deleteLicensePlate: (id: string, params: LicensePlateParams) =>
    del(`${BASE_URL}/${id}/license-plates`, { data: params }),
  contractKMs: (id: string) => get(`${BASE_URL}/${id}/contract/kms`),
  createContractKMs: (id: string, params: ContractKMsParams) =>
    post(`${BASE_URL}/${id}/contract/kms`, params),
  deleteContractKMs: (id: string, params: ContractKMsParams) =>
    del(`${BASE_URL}/${id}/contract/kms`, { data: params }),
};
