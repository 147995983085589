import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row, Table, Typography } from "antd";

const { Text } = Typography;

const inputColumns = (t) => {
  return (
    <Row>
      <Col span={6}>
        <Form.Item label={t("valuePerDayLabel")} name="valuePerDay">
          <InputNumber placeholder="100" min="1" max="100" />
        </Form.Item>
      </Col>
    </Row>
  );
};

const extraColumns = (t) => {
  return [
    {
      title: t("numberOfDaysTitle"),
      dataIndex: "numberOfDays",
      key: "numberOfDays",
    },
    {
      title: t("p/e"),
      dataIndex: "trypLabel",
      key: "trypLabel",
    },
    {
      title: t("valuePerDay"),
      dataIndex: "valuePerDay",
      key: "valuePerDay",
    },
  ];
};

const extraSummary = (t, perDiem, onAdd) => {
  return (
    <>
      <Table.Summary.Row className="subtotal">
        <Table.Summary.Cell colSpan={8} align="right">
          <Text strong>{t("totalDays")}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={2}>
          <Text>
            {perDiem.total.days}{" "}
            <Text disabled className="smaller">
              {t("days")}
            </Text>
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell align="right">
          <Button
            type="primary"
            size="medium"
            icon={<PlusCircleFilled />}
            onClick={() => {
              onAdd();
            }}
          >
            {t("tripButton")}
          </Button>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};

const InternationalPerDiem = {
  inputColumns,
  extraColumns,
  extraSummary,
};

export default InternationalPerDiem;
