import { TFunction } from "i18next";

export const civilState = (
  t: TFunction<"translation", undefined>,
  code: string
) => {
  switch (code) {
    case "001":
      return t("optionSingle");
    case "002":
      return t("optionSingle");
    case "003":
      return t("optionSingle");
    case "004":
    default:
      return t("optionSingle");
  }
};
