import { Breadcrumb as AntdBreadcumb, Col, Row } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import "./Breadcrumbs.css";

function Breadcrumbs() {
  const location = useLocation();
  const { locale, companyId, employeeId, travelReportId } = useParams();
  const [breadcrumbs, setBreadcrumbs] = React.useState();
  const { t } = useTranslation();

  const employeeBreadcrumbs = [
    {
      path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
      breadcrumbName: t("home"),
    },
    {
      path: `/${locale}/company/${companyId}/employees`,
      breadcrumbName: t("employees"),
    },
    {
      path: `/${locale}/company/${companyId}/employee/${employeeId}`,
      breadcrumbName: t("employee"),
    },
  ];

  const travelReportsBreadcrumbs = [
    {
      path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
      breadcrumbName: t("home"),
    },
    {
      path: `/${locale}/company/${companyId}/employees`,
      breadcrumbName: t("employees"),
    },
    {
      path: `/${locale}/company/${companyId}/employee/${employeeId}/travel-reports`,
      breadcrumbName: t("travelReports"),
    },
  ];

  const travelReportBreadcrumbs = [
    {
      path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
      breadcrumbName: t("home"),
    },
    {
      path: `/${locale}/company/${companyId}/employees`,
      breadcrumbName: t("employees"),
    },
    {
      path: `/${locale}/company/${companyId}/employee/${employeeId}/travel-reports`,
      breadcrumbName: t("travelReports"),
    },
    {
      path: `/${locale}/company/${companyId}/employee/${employeeId}/travel-report/${travelReportId}`,
      breadcrumbName: t("travelReport"),
    },
  ];

  React.useEffect(() => {
    if (location.pathname.includes("travel-report/")) {
      setBreadcrumbs(travelReportBreadcrumbs);
    } else if (location.pathname.includes("travel-reports")) {
      setBreadcrumbs(travelReportsBreadcrumbs);
    } else if (
      location.pathname.includes("employee") &&
      !location.pathname.includes("employees")
    ) {
      setBreadcrumbs(employeeBreadcrumbs);
    } else {
      setBreadcrumbs(null);
    }
  }, [location]);

  return (
    <Row>
      <Col span={15} className="breadcrumbs">
        {t && breadcrumbs?.length > 0 && (
          <AntdBreadcumb 
            id="breadcrumbs" 
            items={
              breadcrumbs?.map((breadcrumb) => 
                ( <Link to={breadcrumb.path}>{breadcrumb.breadcrumbName}</Link> )
            ) } />
          )}
      </Col>
    </Row>
  );
}

export default Breadcrumbs;
