import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useStoreActions, useStoreState } from "easy-peasy";

import "./Login.css";

import { apiInstance } from "../../services/Api/base";

const Login = () => {
  const { t } = useTranslation();
  const [error, setError] = React.useState(null);
  const setToken = useStoreActions((state) => state.userModel.setToken);
  const setRBAC = useStoreActions((state) => state.rbacModel.setRBAC);
  const localeState = useStoreState((state) => state.userModel.locale);

  const { accounts, instance } = useMsal();

  const navigate = useNavigate();

  const getMe = () => {
    apiInstance.get("/users/me").then((response) => {
      setRBAC(response.data);
    });
  };

  instance.handleRedirectPromise().then(async (response) => {
    if (response) {
      setToken(response.accessToken);
      apiInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.accessToken}`;
      getMe();
      navigate(`/${localeState}/`, { replace: true });
    } else if (accounts[0]) {
      await instance
        .acquireTokenSilent({
          scopes: ["a6c49e8c-e5ef-44e3-a9b3-f9e9a70a9146/.default"],
          account: accounts[0],
        })
        .then((response) => {
          setToken(response.accessToken);
          apiInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.accessToken}`;
          getMe();
          navigate(`/${localeState}/`, { replace: true });
        })
        .catch(() => {
          console.log("Error acquiring token");
          navigate(`/${localeState}/login/`, { replace: true });
        });
    } else {
      console.log("No response. No accounts found");
    }
  });

  React.useEffect(() => {
    document.title = t("login");
  }, []);

  const signInClickHandler = async (instance) => {
    try {
      await instance.loginPopup({
        scopes: ["a6c49e8c-e5ef-44e3-a9b3-f9e9a70a9146/.default"],
      });
    } catch (error) {
      console.log("SignIn Error", error);
      setError(error);
    }
  };

  function SignInButton() {
    return (
      <>
        <h1>{t("welcomeLogin")}</h1>
        <Button type="primary" onClick={() => signInClickHandler(instance)}>
          {t("login")}
        </Button>
      </>
    );
  }

  function WelcomeUser() {
    const username = accounts[0]?.name ?? '';

    return (
      <>
        <div>
          <Spin size="large" />
        </div>
        <span className="spin-message">{t('welcomeLoginAuth', {username: username})}</span>
      </>
    );
  }

  return (
    <div className="login-form">
      <>
        <AuthenticatedTemplate>
          <WelcomeUser />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          { accounts && accounts.length < 1 && <SignInButton /> } 
        </UnauthenticatedTemplate>
      </>
      {error ? <p>{error}</p> : null}
    </div>
  );
};

export default Login;
