import useHandleApiData from "../../../global/hooks/useHandleApiData";
import { Employees } from "../../../services/Api/Employees";

export const useLicensePlates = (employeeId: string) => {
  return useHandleApiData(
    employeeId,
    Employees.licensePlates,
    Employees.createLicensePlate,
    Employees.deleteLicensePlate
  );
};
