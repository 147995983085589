import { MenuOutlined } from "@ant-design/icons";
import {
  Col,
  Dropdown,
  Input,
  Pagination,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { useStoreState } from "easy-peasy";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate } from "react-router-dom";
import { apiInstance } from "../../services/Api/base";
import "./Employees.css";

const { Title } = Typography;
const { Search } = Input;

function Employees() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onCustomCell = (record, _) => {
    return {
        onClick: (_) => {
          navigate(`/${locale}/company/${companyId}/employee/${record.id}`)
        },
    };
  }

  const columns = [
    {
      title: t("erpId"),
      dataIndex: "erpId",
      key: "erpId",
      width: "100px",
      onCell: onCustomCell,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: "60%",
      onCell: onCustomCell,
    },
    {
      title: t("labelClientColumn"),
      dataIndex: "clientName",
      key: "clientName",
      onCell: onCustomCell,
      render: (clientName) => {
        let color =
          clientName.toUpperCase().indexOf("SEDE") > -1
            ? "lightgrey"
            : clientName.toUpperCase().indexOf("MERCEDES") > -1
            ? "black"
            : clientName.toUpperCase().indexOf("CARLSBERG") > -1
            ? "rgb(23, 177, 105)"
            : clientName.toUpperCase().indexOf("LEROY MERLIN") > -1
            ? "rgb(124, 176, 53)"
            : clientName.toUpperCase().indexOf("WELLA") > -1
            ? "rgb(209, 34, 66)"
            : clientName.toUpperCase().indexOf("FARFETCH") > -1
            ? "rgb(248, 82, 57)"
            : clientName.toUpperCase().indexOf("CELFOCUS") > -1
            ? "rgb(240, 44, 49)"
            : clientName.toUpperCase().indexOf("SANTANDER") > -1
            ? "rgb(230, 43, 48)"
            : clientName.toUpperCase().indexOf("MAERSK") > -1
            ? "rgb(76, 175, 203)"
            : clientName.toUpperCase().indexOf("BOOST") > -1
            ? "rgb(64, 135, 249)"
            : clientName.toUpperCase().indexOf("SHIELD") > -1
            ? "rgb(255, 216, 64)"
            : clientName.indexOf("BNP") > -1
            ? "rgb(0, 133, 92)"
            : clientName.indexOf("GLS") > -1
            ? "rgb(255, 213, 88)"
            : clientName.indexOf("NOS") > -1
            ? "black"
            : "grey";
        return (
          clientName && (
            <Tag color={color} key={clientName}>
              {clientName}
            </Tag>
          )
        );
      },
    },
    {
      title: t("actionLabel"),
      key: "action",
      width: 160,
      align: "right",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: 0,
                  label: (
                    <Link
                      to={`/${locale}/company/${companyId}/employee/${record.id}`}
                    >
                      {t("viewEmployeeLink")}
                    </Link>
                  ),
                },
                {
                  key: 1,
                  label: (
                    <Link
                      to={`/${locale}/company/${companyId}/employee/${record.id}/travel-reports`}
                    >
                      {t("travelReportsButton")}
                    </Link>
                  ),
                },
                {
                  key: 2,
                  label: (
                    <Link
                      to={`/${locale}/company/${companyId}/employee/${record.id}/per-diems/NATIONAL`}
                    >
                      {t("nPerDiemsButton")}
                    </Link>
                  ),
                },
                // {
                //   key: 3,
                //   label: (
                //     <Link
                //       to={`/${locale}/company/${companyId}/employee/${record.id}/per-diems/INTERNATIONAL`}
                //     >
                //       {t("iPerDiemsButton")}
                //     </Link>
                //   ),
                // },
              ],
            }}
          >
            <a href="/" onClick={(e) => e.preventDefault()}>
              <MenuOutlined />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const { locale, companyId } = useParams();
  const companyName = useStoreState((state) => state.companyModel.name);
  const [loadingEmployees, setLoadingEmployees] = React.useState(true);
  const [employees, setEmployees] = React.useState(null);
  const [pagination, setPagination] = React.useState(null);
  const [current, setCurrent] = React.useState(1);

  const [searchText, setSearchText] = React.useState("");

  const handleSearch = (selectedKeys) => {
    typeof selectedKeys === "object"
      ? (selectedKeys = selectedKeys.target.value)
      : (selectedKeys = selectedKeys);
    if (selectedKeys.length < 3 && selectedKeys !== "") return;
    setEmployees(null);
    setCurrent(1);
    setSearchText(selectedKeys);
  };

  const getEmployees = async (current) => {
    setLoadingEmployees(true);
    apiInstance
      .get(
        `/employees/employees?companyId=${companyId}&page=${
          current - 1
        }&size=10${searchText.length > 2 ? `&search=${searchText}` : ""}`
      )
      .then((response) => {
        setEmployees(response.data.content);
        setPagination(response.data.pagination);
        setLoadingEmployees(false);
      });
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  React.useEffect(() => {
    if (searchText && searchText.length > 2) {
      let timer = setTimeout(() => {
        getEmployees(current);
      }, 400);

      return () => clearTimeout(timer);
    } else {
      getEmployees(current);
    }
  }, [current, searchText]);

  React.useEffect(() => {
    document.title = `Employees${
      typeof companyName === "string" ? ` — ${companyName}` : ""
    }`;
  }, []);

  return (
    <>
      <Row>
        <Col span={20}>
          <Title level={2} className="pageTitle">
            {t("employees")}
          </Title>
        </Col>
        <Col span={4} align="right">
          <Search
            placeholder={t("employeeSearchPlaceholder")}
            onSearch={handleSearch}
            onChange={handleSearch}
            enterButton
            allowClear
          />
        </Col>
      </Row>
      <Table
        loading={loadingEmployees}
        dataSource={employees}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      {employees && pagination.totalPages > 1 && (
        <Pagination
          onChange={onChange}
          current={current}
          total={pagination.totalElements}
          showSizeChanger={false}
        />
      )}
    </>
  );
}

export default Employees;
