import { Button, Col, Row, Space, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import useIsOpen from "../../../../global/hooks/useIsOpen";
import CreateNewFieldModalForm from "./CreateNewFieldModalForm";
import { IActiveValue } from "./FiledWithHistory.interface";

const ActiveValue = ({
  field,
  onCreate,
  valueFormComponent,
  modalDescription,
  showOnCreate,
}: IActiveValue) => {
  const { isOpen, open, close } = useIsOpen();
  const { value, date } = field ? field : { value: undefined, date: undefined };
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size="large" style={{ whiteSpace: "nowrap" }}>
      <Row gutter={56}>
        <Col span={12}>
          <Statistic title={t("employee.active")} value={value || "N/D"} />
        </Col>
        <Col span={12}>
          <Statistic
            title={t("employee.validSince")}
            value={date ? date.format("MMM YYYY") : "N/D"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          { onCreate !== undefined && <Button onClick={open}>{t("add")}</Button> }
          <CreateNewFieldModalForm
            open={isOpen}
            onCreate={(values) => {
              close();
              if (onCreate !== undefined) {
                onCreate(values);
              }
            }}
            onCancel={close}
            valueFormComponent={valueFormComponent}
            description={modalDescription}
          />
        </Col>
      </Row>
    </Space>
  );
};

export default ActiveValue;
