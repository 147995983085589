import { createContext, useContext } from "react";
import { IEmployee } from "./Employee.interface";

const EmployeeContext = createContext<IEmployee | null>(null);

export const useEmployeeContext = () => {
  const context = useContext(EmployeeContext);

  if (!context) {
    throw new Error(
      "Employee.* component must be rendered as child of Employee component"
    );
  }

  return context;
};

export default EmployeeContext;
