import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useStoreActions } from 'easy-peasy';

import './Logout.css';

function Logout() {
  const { locale } = useParams();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const setToken = useStoreActions((state) => state.userModel.setToken);

  instance.logout().then((response) => {
    setToken(null);
    console.log('Logged out successfully');

    navigate(`/${locale}/login/`, { replace: true });
  });

  React.useEffect(() => {
    document.title = `Logout`;
  }, []);
}

export default Logout;