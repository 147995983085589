import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCompanyName } from "../../../global/utils";
import { Employees } from "../../../services/Api/Employees";
import { IEmployee } from "../Employee.interface";
import { civilState } from "../utils";

export const useEmployeeDetails = (employeeId: string | undefined) => {
  const [employee, setEmployee] = useState<IEmployee | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (employeeId) {
      Employees.single(employeeId)
        .then((response) => {
          const employeeData: IEmployee = response.data;
          //convert a company id into a name
          employeeData.companyName = getCompanyName(employeeData.company);
          //convert civil state to a comprehensible string
          employeeData.civilState = civilState(t, employeeData.civilState);

          setEmployee(employeeData);
        })
        .catch((error) => {
          console.warn("error", error);
        });
    }
  }, [employeeId]);

  return employee;
};
