import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

const useHandleApiData = (
  extraParams: string,
  fetchFn: (params: string) => Promise<AxiosResponse<any, any>>,
  createFn: (params: string, item: any) => Promise<AxiosResponse<any, any>>,
  deleteFn: (params: string, item: any) => Promise<AxiosResponse<any, any>>
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = () => {
    setIsLoading(true);
    fetchFn(extraParams).then((response) => {
      setData(response.data.content);
      setIsLoading(false);
    });
  };

  const deleteData = (dataItem: any) => {
    setIsLoading(true);
    deleteFn(extraParams, dataItem).then(() => {
      fetchData();
    });
  };

  const createData = (dataItem: any) => {
    setIsLoading(true);
    createFn(extraParams, dataItem).then(() => {
      fetchData();
    });
  };

  useEffect(() => {
    fetchData();
  }, [extraParams]);

  return { data, isLoading, createData, deleteData };
};

export default useHandleApiData;
