import { Col, Row, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IPeriod } from "./Period.interface";

const Period = ({ month, year }: IPeriod) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col span={14} style={{ textAlign: "right" }}>
          <Text type="secondary">{t("month")}</Text>
        </Col>
        <Col span={10} style={{ textAlign: "right" }}>
          <Text type="secondary">{t("year")}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={14} style={{ textAlign: "right" }}>
          <Text className="date" style={{ textTransform: "capitalize" }}>
            {dayjs()
              .month(month - 1)
              .format("MMMM")}
          </Text>
        </Col>
        <Col span={10} style={{ textAlign: "right" }}>
          <Text className="date">{year}</Text>
        </Col>
      </Row>
    </>
  );
};

export default Period;
