import { Descriptions } from "antd";
import { useTranslation } from "react-i18next";
import { useEmployeeContext } from "../Employee.context";
import { IEmployee } from "../Employee.interface";

const EmployeeDetail = () => {
  const employee = useEmployeeContext();
  const { t } = useTranslation();

  const items = [
    "name",
    "civilIdNumber",
    "civilIdValidityDate",
    "fiscalIdNumber",
    "socialSecurityNumber",
    "address",
    "birthdate",
    "hiringDate",
    "civilState",
    "dependentsNumber",
    "disabledDependentsNumber",
    "gender",
    "emailAddress",
    "erpId",
    "companyName",
    "postalCode",
    "postalLocation",
    "clientName",
  ].map((key, index) => {
    return {
      key: index,
      label: t(key),
      children: employee[key as keyof IEmployee],
    };
  });

  return <Descriptions bordered column={2} items={items} size="small" />;
};

export default EmployeeDetail;
