import { Form, InputNumber, Tabs } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import { useEmployeeContext } from "../Employee.context";
import { useEmployeeContractKMs } from "../hooks/useEmployeeContractKMs";
import FieldWithHistory from "./FieldWithHistory";
import { IField } from "./FieldWithHistory/FiledWithHistory.interface";

const EmployeeBenefits = () => {
  const employee = useEmployeeContext();
  const {
    data: contractKMsData,
    isLoading,
    createData: createContractKMs,
    deleteData: deleteContractKMs,
  } = useEmployeeContractKMs(employee.id);
  const { t } = useTranslation();

  if (isLoading) return <Loading />;

  const onCreateContractKMs = (values: IField) => {
    createContractKMs({
      value: values.value,
      validSince: values.date.format("YYYY-MM-01"),
    });
  };

  const onDeleteContractKMs = (values: IField) => {
    deleteContractKMs({
      value: parseFloat(values.value.replace(" €", "")),
      validSince: values.date.format("YYYY-MM-DD"),
    });
  };

  const onCreateContractAC = (values: IField) => {
    console.log('TODO: onCreateContractAC')
  };

  const onDeleteContractAC = (values: IField) => {
    console.log('TODO: onDeleteContractAC')
  };

  const contractKMs = {
    label: t("employee.kms"),
    key: "contractKMs",
    children: (
      <FieldWithHistory
        data={contractKMsData.map(({ value, isCurrent, validSince }) => ({ 
          value: `${value} €`,
          isCurrent,
          date: dayjs(validSince),
        }))}
        description={t("employee.kms.description")}
        onCreate={onCreateContractKMs}
        onDelete={onDeleteContractKMs}
        modalDescription={t("employee.newCompensation")}
        valueFormComponent={
          <Form.Item
            name="value"
            label={t("newValue")}
            rules={[{ required: true, message: t("enterNumberMessage") }]}
          >
            <InputNumber />
          </Form.Item>
        }
      />
    ),
  };

  const contractACs = {
    label: t("employee.ac"),
    key: "contractACs",
    children: (
      <FieldWithHistory
        data={[]}
        description={t("employee.ac.description")}
        //onCreate={onCreateContractAC}
        showOnCreate={false}
        onDelete={onDeleteContractAC}
        modalDescription={t("employee.newCompensation")}
        valueFormComponent={
          <>
            <Form.Item
              name="valueDay"
              label={t("employee.ac.newValueDay")}
              rules={[{ required: true, message: t("enterNumberMessage") }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="valueTotalMonth"
              label={t("employee.ac.newTotalMonth")}
              rules={[{ required: true, message: t("enterNumberMessage") }]}
            >
              <InputNumber />
            </Form.Item>
          </>
        }
      />
    ),
  }

  return <Tabs tabPosition="left" items={[contractKMs, contractACs]} />;
};

export default EmployeeBenefits;
