import { MsalProvider } from "@azure/msal-react";
import { StoreProvider } from "easy-peasy";
import { createRoot } from "react-dom/client";
import Router from "./router";
import { msalInstance } from './services/Api/base';
import store from "./store";

import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <MsalProvider instance={msalInstance}>
    <StoreProvider store={store}>
      <Router />
    </StoreProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
