import { useState } from "react";

const useIsOpen = (initialValue?: boolean) => {
  const [isOpen, setIsOpen] = useState(initialValue || false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return { isOpen, close, open };
};

export default useIsOpen;
